
//import { GeoSearchControl, OpenStreetMapProvider, EsriProvider } from "leaflet-geosearch";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { GeoSearchControl } from 'leaflet-geosearch';



export const getFetch1 = async (latitude, longitude) => {
    try {

        
        const GEOCODE_URL = "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location="
        
        const data = await ( await fetch(GEOCODE_URL+`${longitude},${latitude}`)).json();
        //console.log(data.address);
    
        let respuesta = data.address.LongLabel
        
        return respuesta;

    }
    catch (e) {
        console.log('We have the error', e);
    }
}