import React, { useState } from "react";
import ReactDOMServer from 'react-dom/server';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMap } from "react-leaflet";
import Leaflet from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "leaflet/dist/leaflet.css";

import "../assets/css/maps.css";


// FontAwesome requirements
import { faStreetView } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faStreetView)

function ChangeMapView({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
}

const UbicaUser = (props) => {

  const latitud = props.latitud;
  const longitud = props.longitud;
  const accuracy = Math.round(props.accuracyMap);

  const iconHTML = ReactDOMServer.renderToString(<FontAwesomeIcon icon='street-view' size="2x" transform="up-6 left-5 grow-1" />)
  const customMarkerIcon = new Leaflet.DivIcon({
    html: iconHTML,
  });


  const position = { lat: latitud, lng: longitud };
  const position1 = [latitud, longitud];



  return (


    <MapContainer className="leaflet-container popupText1"
      id={latitud}
      center={[latitud, longitud]}
      zoom={15}

    >
      <TileLayer style={{ tileSize: 256, zoomOffset: -1 }}
        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
      />
      <Marker
        key='2a'
        position={position1}
        data="customdata"
        icon={customMarkerIcon}
      >
        <Popup>
          <div className="popupText1">
            Usted esta ubicado en un radio {Math.round(accuracy)} metros.<br /> de este punto.!<br />
          </div>
        </Popup>
      </Marker>

      <Circle key='1a' center={position1} radius={accuracy} />
      <ChangeMapView coords={position} />
    </MapContainer>

  );
};

export default UbicaUser;