import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { Form, Button, Row, Col, Container, Modal, InputGroup } from 'react-bootstrap';
import { motion } from 'framer-motion';

import startsWith from 'lodash.startswith';
import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/high-res.css'
import 'react-phone-input-2/lib/bootstrap.css'

import Registro_User from "../../components/Registro_User";

import ElementosService from "../../services/getElementos.service";

import Swal from 'sweetalert2';


import { ingresoentidad } from "../../actions/auth";

const SecondStep = (props) => {
  const { user } = props;

  const { register, handleSubmit, setError, watch, formState: { errors } } = useForm({
    defaultValues: {
      user_nombre: user.user_nombre,
      user_apellido: user.user_apellido,
      user_email: user.user_email,
      user_fono: user.user_fono,
      user_entidad: user.user_entidad,
      user_usuario: user.user_usuario,
      user_password: user.user_password,
      user_password1: user.user_password1
    }
  });

  const [valuefono, setValue] = useState(user.user_fono)
  const [valuefonoEntidad, setValueEntidad] = useState('')
  const [TextTipoEntidad, setTextTipoEntidad] = useState('');
  const [TextNombreEntidad, setTextNombreEntidad] = useState('');
  const [TextNombreCEntidad, setTextNombreCEntidad] = useState('');
  const [TextDireccionEntidad, setTextDireccionEntidad] = useState('');
  const [TextEmailEntidad, setTextEmailEntidad] = useState('');


  const [validated, setValidated] = useState(false);
  const [isLoadingE, setIsLoadingE] = useState(false);

  const dispatch = useDispatch();

  const onSubmitEntidad = (e) => {
    e.preventDefault()

    try {
      dispatch(ingresoentidad(
        TextNombreEntidad.toLowerCase(),
        TextDireccionEntidad,
        user.user_accuracy,
        user.user_currentLatitude,
        user.user_currentLongitude,
        TextEmailEntidad.toLowerCase(),
        valuefonoEntidad,
        TextNombreCEntidad.toLowerCase(),
        'S/D',
        user.user_pais,
        TextTipoEntidad.toLowerCase()
      ))
        .then((datos) => {

          if (datos.message === 'Solicitud de Entidad se registró con éxito!') {
            setIsLoadingE(true)
            handleClose();
          }
          //handleClose();
          //return response.data;
        })
        .catch((error) => {
          console.log('dispatch: ' + error)
        })
    } catch (error) {

      console.log('try: ' + error)
    }


    //handleClose();
  }

  const onSubmit = (data) => {
    if (typeof (valuefono) !== "undefined") {

      //console.log(valuefono);
      var cadena1 = valuefono.slice(0, 1);
      if (cadena1 != '+') {
        data.user_fono = '+' + valuefono;
      } else {
        data.user_fono = valuefono;
      }

      props.updateUser(data);
      props.history.push('/registro/third');

    } else {
      Swal.fire({
        icon: 'error',
        title: "Error al verificar: (teléfono)",
        showConfirmButton: false,
        text: "El teléfono ingresado es incorrecto o no existe.!",
        timer: 10000
      });
    }

  };

  const [entidades, setEntidades] = useState([]);
  const [tipoEntidades, setTipoEntidades] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntidad, setSelectedEntidad] = useState('');
  const [selectedtipoEntidad, setSelectedtipoEntidad] = useState('');
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (isMounted) {

      const getEntidad = async () => {
        try {
          setIsLoading(true);

          const resultTipo = await ElementosService.getTipoEntidades();
          let alltipoEntidades = [];
          alltipoEntidades = resultTipo?.map(({ tipo_entidad_id, name }) => ({
            tipo_entidad_id,
            name
          }));
          const [{ name: firsttipoEndidad } = {}] = alltipoEntidades;
          setTipoEntidades(alltipoEntidades);
          setSelectedtipoEntidad(firsttipoEndidad);

          const result = await ElementosService.getEntidades();
          let allEntidades = [];
          allEntidades = result?.map(({ entidad_id, name }) => ({
            entidad_id,
            name
          }));
          const [{ name: firstEndidad } = {}] = allEntidades;
          setEntidades(allEntidades);
          setSelectedEntidad(firstEndidad);
          setIsLoading(false);
        } catch (error) {
          setEntidades([]);
          setIsLoading(false);
        }
      };

      getEntidad();


    }
  }, []);
  useEffect(() => {
    return () => {
      setIsMounted(false);
    }
  }, []);
  //  console.log(entiadesSist.entidadList[1].name);
  //  console.log(entiadesSist.total);

  const user_password = useRef({});
  user_password.current = watch("user_password", "");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Form className="col-titulo-login" onSubmit={handleSubmit(onSubmit)}>
      <Registro_User />
      <motion.div
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >
        <Container className="um_data">
          <Row >
            <Col sm={6}>
              <Form.Group controlid="user_nombre">
                <Form.Label className="p-2">Nombre</Form.Label>
                <Form.Control
                  
                  type="text"
                  name="user_nombre"
                  placeholder="Ej: Juan"
                  autoComplete="off"
                  {...register("user_nombre", {
                    required: {
                      value: true,
                      message: 'Nombre es requerido'
                    },
                    maxLength: {
                      value: 20,
                      message: 'No más de 20 carácteres!'
                    },
                    minLength: {
                      value: 3,
                      message: 'Mínimo 3 carácteres'
                    },
                    pattern: {
                      value: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
                      message: 'El nombre sólo puede contener letras y espacios.'
                    }
                  })}

                  className={`p-2 ${errors.user_nombre ? 'input-error' : ''}`}
                />

              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlid="user_apellido">
                <Form.Label className="p-2">Apellido</Form.Label>
                <Form.Control
                  
                  type="text"
                  name="user_apellido"
                  placeholder="Ej: Perez"
                  autoComplete="off"
                  {...register("user_apellido", {
                    required: {
                      value: true,
                      message: 'Apellido es requerido'
                    },
                    maxLength: {
                      value: 20,
                      message: 'No más de 20 carácteres!'
                    },
                    minLength: {
                      value: 4,
                      message: 'Mínimo 4 carácteres'
                    },
                    pattern: {
                      value: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
                      message: 'El apellido sólo puede contener letras y espacios.'
                    }
                  })}
                  className={`p-2 ${errors.user_apellido ? 'input-error' : ''}`}
                />
                {errors.user_apellido && (
                  <p className="errorMsg">{errors.user_apellido.message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group controlid="user_email">
                <Form.Label className="p-2">Email</Form.Label>
                <Form.Control
                  
                  type="email"
                  name="user_email"
                  placeholder="Ej: email@email.com"
                  autoComplete="false"

                  {...register("user_email", {
                    required: {
                      value: true,
                      message: 'Email es requerido'
                    },
                    maxLength: {
                      value: 50,
                      message: 'No más de 50 carácteres!'
                    },
                    minLength: {
                      value: 5,
                      message: 'Mínimo 5 carácteres'
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: 'El email sólo puede contener carácteres y sin espacios.'
                    }
                  })}
                  className={`p-2 ${errors.user_email ? 'input-error' : ''}`}
                />
                {errors.user_email && (
                  <p className="errorMsg">{errors.user_email.message}</p>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlid="user_fono">
                <Form.Label className="p-2">Teléfono</Form.Label>
                <PhoneInput
                  
                  value={user.user_fono}
                  onChange={(user_fono) => setValue(user_fono)}
                  placeholder='ingrese teléfono'
                  enableAreaCodes={true}

                  inputProps={{
                    name: 'user_fono',
                    required: true,
                    autoFocus: false,
                    autoComplete: 'random-string'
                  }}
                  onlyCountries={['cl']}
                  country={'cl'}
                  masks={{ cl: '. .. ... ...' }}
                  isValid={(value, country, countries) => {
                    return countries.some((country) => {
                      return startsWith(value, country.dialCode) || startsWith(country.dialCode, value);
                    });
                  }}
                  className={`p-2 ${errors.user_fono ? 'input-error' : ''}`}
                />
                {errors.user_fono && (
                  <p className="errorMsg">{errors.user_fono.message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group controlid="user_entidad">
                <Form.Label className="p-2">Entidad</Form.Label>
                {isLoading && (
                  <span className="loading">Búscando Entiades. Por favor espere...</span>
                )}
                <Form.Control
                  
                  as="select"
                  name="entidad"
                  value={selectedEntidad}
                  {...register("user_entidad", {
                    required: {
                      value: true,
                      message: 'Debe seleccionar una Entidad.',
                    }
                  })}
                  onChange={(event) => {
                    setSelectedEntidad(event.target.value)
                  }
                  }

                  className={`p-2 ${errors.user_entidad ? 'input-error' : ''}`}
                >
                  {errors.user_entidad && (
                    <p className="errorMsg">{errors.user_entidad.message}</p>
                  )}
                  {entidades.map(({ entidad_id, name }) => (
                    <option value={name} key={entidad_id}>
                      {name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group controlid="user_usuario">
                <Form.Label className="p-2">Usuario</Form.Label>
                <Form.Control
                  
                  type="text"
                  name="user_usuario"
                  placeholder="Ej: jperez"
                  autoComplete="new-user"

                  {...register("user_usuario", {
                    required: {
                      value: true,
                      message: 'Usuario es requerido'
                    },
                    maxLength: {
                      value: 20,
                      message: 'No más de 20 carácteres!'
                    },
                    minLength: {
                      value: 4,
                      message: 'Mínimo 4 carácteres'
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_-]{4,16}$/,
                      message: 'Usuario sólo puede tener letras, numeros, guion y guion_bajo.'
                    }
                  })}

                  className={`p-2 ${errors.user_usuario ? 'input-error' : ''}`}
                />
                {errors.user_usuario && (
                  <p className="errorMsg">{errors.user_usuario.message}</p>
                )}
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlid="user_password">
                <Form.Label className="p-2">Clave</Form.Label>
                <Form.Control
                  
                  type="password"
                  name="user_password"
                  placeholder="(4-12 digitos)"
                  autoComplete="new-password"

                  {...register("user_password", {
                    required: {
                      value: true,
                      message: 'Password es requerido'
                    },
                    maxLength: {
                      value: 12,
                      message: 'No más de 12 digitos!'
                    },
                    minLength: {
                      value: 4,
                      message: 'Mínimo 4 digitos!'
                    },
                    pattern: {
                      value: /^[0-9]{4,12}$/,
                      message: 'Password sólo puede tener de 4 a 12 digitos.'
                    }
                  })}
                  className={`p-2 ${errors.user_password ? 'input-error' : ''}`}
                />
                {errors.user_password && (
                  <p className="errorMsg">{errors.user_password.message}</p>
                )}
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlid="user_password1">
                <Form.Label className="p-2">Repetir Clave</Form.Label>
                <Form.Control
                  
                  type="password"
                  name="user_password1"
                  placeholder="Confirmar password"
                  autoComplete="new-password"

                  {...register("user_password1", {
                    validate: value =>
                      value === user_password.current || "Las password no son iguales.!",
                    required: {
                      value: true,
                      message: 'Repetir el Password es requerido'
                    },
                    maxLength: {
                      value: 12,
                      message: 'No más de 12 digitos!'
                    },
                    minLength: {
                      value: 4,
                      message: 'Mínimo 4 digitos!'
                    },
                    pattern: {
                      value: /^[0-9]{4,12}$/,
                      message: 'Password sólo puede tener de 4 a 12 digitos.'
                    }
                  })}
                  className={`p-2 ${errors.user_password1 ? 'input-error' : ''}`}
                />
                {errors.user_password1 && (
                  <p className="errorMsg">{errors.user_password1.message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="um_data-login-row">
            <div className="d-grid gap-2">
              <Button
                className="btn btn-primary d-grid gap-2 p-2 my-2 btn-fill"
                variant="primary"
                type="submit"
              >
                Siguiente (Paso 3)
              </Button>
            </div>
          </Row>
          <Row className="um_data-login-row">
            <div className="d-grid gap-2 p-2 my-2">
              <Button
                className="btn btn-secondary btn-fill"
                variant="secondary"
                onClick={handleShow}
                disabled={false}
              >
                <span>
                  {isLoadingE ? 'Solicitud enviada con éxito!' : 'Solicitar crear nueva "Entidad" '}
                </span>



              </Button>
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}

            >
              <Modal.Header closeButton>
                <Modal.Title>Solicitud: NUEVA ENTIDAD</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Por favor ingresar los antecedentes para el Administrador, pueda crear la nueva ENTIDAD:
                <hr></hr>
                <Container className="align-items-center d-flex "  >

                  <Form className="needs-validation" noValidate onSubmit={onSubmitEntidad}>
                    <Row className="d-inline-flex" >
                      <Form.Group className="p-2 shadow-sm text-primary">
                        <Form.Label>Tipo de Entidad</Form.Label>
                        <select
                          className="form-select"
                          id="new_tipoentidad"
                          onChange={(event) => {
                            setTextTipoEntidad(event.target.value)
                          }
                          }
                          required>
                          <option disabled value="">Tipo...</option>
                          {tipoEntidades.map(({ entidad_id, name }) => (
                            <option value={entidad_id} key={entidad_id}>
                              {name.toUpperCase()}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          Please select a valid state.
                        </div>
                      </Form.Group>

                      <Form.Group className="p-2 my-2 shadow-sm text-primary" controlid="newEntidad_nombre">
                        <Form.Label>Nombre de la Entidad (Comunidad)</Form.Label>
                        <Form.Control
                          name="newEntidad_nombre"
                          placeholder="Ej: Condominio Las Parcelas"
                          type="text"
                          autoComplete="off"
                          required

                          onChange={(event) => {
                            setTextNombreEntidad(event.target.value)
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="p-2 my-2 shadow-sm text-primary" controlid="newEntidad_nombrcontacto">
                        <Form.Label>Nombre del contacto</Form.Label>
                        <Form.Control
                          name="newEntidad_nombrcontacto"
                          placeholder="Ej: Juan Peréz"
                          type="text"
                          autoComplete="off"
                          required

                          onChange={(event) => {
                            setTextNombreCEntidad(event.target.value)
                          }}
                        />
                      </Form.Group>


                      <Form.Group className="p-2 my-2 shadow-sm text-primary" controlid="newEntidad_direccion">
                        <Form.Label>Dirección (nombre de calle y N°)</Form.Label>
                        <Form.Control
                          name="newEntidad_direccion"
                          placeholder="Ej: Avda. Los Jarros N° 1210"
                          type="text"
                          required
                          onChange={(event1) => {
                            setTextDireccionEntidad(event1.target.value)
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="p-2 shadow-sm text-primary" controlid="newEntidad_email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="newEntidad_email"
                          placeholder="Ej: correo@corre.com"
                          type="text"
                          required
                          onChange={(event1) => {
                            setTextEmailEntidad(event1.target.value)
                          }}

                        />
                      </Form.Group>
                      <Form.Group className="p-2 shadow-sm text-primary" controlid="newEntidad_telefono">
                        <Form.Label>Teléfono contacto</Form.Label>
                        <PhoneInput

                          placeholder='ingrese teléfono'
                          enableAreaCodes={true}
                          onChange={(user_fono) => setValueEntidad(user_fono)}
                          inputProps={{
                            name: 'newEntidad_telefono',
                            required: true,
                            autoComplete: 'random-string'
                          }}
                          onlyCountries={['cl']}
                          country={'cl'}
                          masks={{ cl: '. .. ... ...' }}
                          isValid={(value, country, countries) => {
                            return countries.some((country) => {
                              return startsWith(value, country.dialCode) || startsWith(country.dialCode, value);
                            });
                          }}
                        />


                      </Form.Group>
                    </Row>
                    <hr></hr>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button className="p-2 btn-fill" variant="secondary" onClick={handleClose}>
                        Cerrar
                      </Button>
                      <Button
                        className="btn btn-primary p-2 btn-fill btn-lg"
                        type="submit"
                      >
                        Enviar solicitud
                      </Button>


                    </div>
                  </Form>

                </Container>
              </Modal.Body>

              <Modal.Footer className="text-danger">
                Nota: El Administrador del Sistema, debe autorizar la nueva ENTIDAD antes de poder utilizarla en su registro como usuario.
              </Modal.Footer>
              <hr></hr>
            </Modal>
          </Row>
        </Container>
      </motion.div>
    </Form >
  );
};

export default SecondStep;
