import React from "react";
import AuthService from "../services/auth.service";
import { Form, Button, Row, Col, Container } from 'react-bootstrap';


const Welcome = (props) => {

  if (props.match.path === "/confirm/:confirmationCode") {
    AuthService.verifyUser(props.match.params.confirmationCode);
  }

  function myFunction() {
    document.body.style.backgroundColor = "#ffff";
    document.body.style.backgroundImage = "url('./assets/icons/FondoBase05.png')";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundPosition = "center center";
  }

  myFunction();

  const onSubmit = (e) => {

  }

  return (
    <Form className="input-form-login">

      <div className="text-center p-3">
        <img src={process.env.REACT_APP_LOGOEMPRESA} width="350" alt="" loading="lazy" />
      </div>
      <br />
      <br />
      <div className="container align-items: center">
        <h2>
          <strong>Gracias, ya confirmamos tú correo electrónico!</strong><br />
          <strong>El Administrador ahora debe activar la cuenta, para que puedas acceder al Sistema.!</strong>
        </h2>

      </div>
      <br />
      <br />
      <br />
      <Row className="um_data-login-row">
        <div className="d-grid gap-2">
          <Button
            className="btn btn-primary btn-fill"
            variant="success"
            type="submit"
            href="/"
          >
            ir al sistema
          </Button>
        </div>
      </Row>
    </Form>
  );
};

export default Welcome;