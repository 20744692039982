import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, {css} from 'styled-components';

const colores = {
	borde: "#0075FF",
	error: "#bb2929",
	exito: "#1ed12d"
}


const IconoUbica = styled(FontAwesomeIcon)`
	position: absolute;
	left: 5px;
	bottom: 5px;
	z-index: 100;
	font-size: 40px;
	opacity: 0;

	${props => props.valido === 'false' && css`
		opacity: 1;
		color: ${colores.error};
	`}

	${props => props.valido === 'true' && css`
		opacity: 1;
		color: ${colores.exito};
	`}
`;

const IconoUbica1 = styled(FontAwesomeIcon)`
	font-size: 45px;
	opacity: 0;
	padding: 0px 0 0 5px;
	position: flex;
	border-radius: 5px;
	
	
	${props => props.valido === 'false' && css`
		opacity: 1;
		color: ${colores.error};
	`}

	${props => props.valido === 'true' && css`
		opacity: 1;
		color: ${colores.exito};
	`}
`;



export {
    IconoUbica,
    IconoUbica1,
};