import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { motion } from 'framer-motion';

import startsWith from 'lodash.startswith';
import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/high-res.css'
import 'react-phone-input-2/lib/bootstrap.css'

import Registro_UserRec from "../../components/Registro_UserRec";
import ElementosService from "../../services/getElementos.service";

import { usertel } from "../../actions/auth";
import Swal from 'sweetalert2';

const SecondStep = (props) => {
  const { user } = props;



  const { register, handleSubmit, setError, watch, formState: { errors } } = useForm({
    defaultValues: {
      user_email: user.user_email,
      user_usuario: user.user_usuario,
      user_nombre: user.user_nombre,
      user_fono: user.user_fono,
      user_id: user.user_id
    }
  });

  const [valuefono, setValue] = useState(user.user_fono)
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    props.updateUser(data);

    dispatch(
      usertel(
        data.user_email,
        data.user_usuario)
    )
      .then((datos) => {
        data.user_fono = datos.telefono;
        data.user_nombre = datos.nombres;
        data.user_id = datos.user_id;
        props.updateUser(data);
        props.history.push('/recupera/third');

      })
      .catch(() => {
        setIsLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Error al verificar: (" + data.user_email + ")",
          showConfirmButton: false,
          text: "El nombre de usuario ingresado (" + data.user_usuario + ") es incorrecto ó el email ingresado es incorrecto.!",
          timer: 10000
        });

      });



    //props.history.push('/recupera/third');
  };

  const [entidades, setEntidades] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntidad, setSelectedEntidad] = useState('');


  return (
    <Form className="col-titulo-login" onSubmit={handleSubmit(onSubmit)}>
      <Registro_UserRec />
      <motion.div
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >
        <Container className="um_data">
          <Row>
            <Col sm={12}>
              <Form.Group controlid="user_email">
                <Form.Label>Ingrese su Email</Form.Label>
                <Form.Control
                  type="email"
                  name="user_email"
                  placeholder="Ej: email@email.com"
                  autoComplete="false"

                  {...register("user_email", {
                    required: {
                      value: true,
                      message: 'Email es requerido'
                    },
                    maxLength: {
                      value: 30,
                      message: 'No más de 20 carácteres!'
                    },
                    minLength: {
                      value: 5,
                      message: 'Mínimo 5 carácteres'
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: 'El email sólo puede contener carácteres y sin espacios.'
                    }
                  })}
                  className={`${errors.user_email ? 'input-error' : ''}`}
                />
                {errors.user_email && (
                  <p className="errorMsg">{errors.user_email.message}</p>
                )}
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group controlid="user_usuario">
                <Form.Label>Ingrese el Usuario</Form.Label>
                <Form.Control
                  type="text"
                  name="user_usuario"
                  placeholder="Ej: jperez"
                  autoComplete="new-user"

                  {...register("user_usuario", {
                    required: {
                      value: true,
                      message: 'Usuario es requerido'
                    },
                    maxLength: {
                      value: 20,
                      message: 'No más de 20 carácteres!'
                    },
                    minLength: {
                      value: 4,
                      message: 'Mínimo 4 carácteres'
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_-]{4,16}$/,
                      message: 'Usuario sólo puede tener letras, numeros, guion y guion_bajo.'
                    }
                  })}

                  className={`${errors.user_usuario ? 'input-error' : ''}`}
                />
                {errors.user_usuario && (
                  <p className="errorMsg">{errors.user_usuario.message}</p>
                )}
              </Form.Group>
            </Col>

          </Row>
          <Row className="um_data-login-row">
            <div className="d-grid gap-2">
              <Button
                className="btn btn-primary btn-fill"
                variant="primary"
                type="submit"
              >
                Siguiente (Paso 3)
              </Button>
            </div>
          </Row>
        </Container>

      </motion.div>
    </Form>
  );
};

export default SecondStep;
