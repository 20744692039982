import React from "react";
import { Form, Button, Card } from 'react-bootstrap';

function myFunction() {
  document.body.style.backgroundColor = "#ffff";
  document.body.style.backgroundImage = "url('./assets/icons/FondoBase05.png')";
  document.body.style.backgroundRepeat = "no-repeat";
  document.body.style.backgroundSize = "cover";
  document.body.style.backgroundAttachment = "fixed";
  document.body.style.backgroundPosition = "center center";
}

myFunction();

function Error404() {
  return (
    <Card className='mx-auto mt-4' border="warning" style={{ width: '18rem' }}>
      <Card.Img className='mx-auto p-4' variant="top" style={{ width: '90%', height: 'auto' }} src={process.env.REACT_APP_LOGOEMPRESASGS} />
      <Card.Body>
        <Button
          className="btn-fill pull-right mb=2 d-grid gap-2"
          variant="primary"
          href="/"
          size="lg"
          value="ira">
          Iniciar sesión
        </Button>
      </Card.Body>
    </Card>

  );
}

export default Error404;