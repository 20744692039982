import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions/types";

var CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");

let user = ""
try {
  
  if (localStorage.getItem("userSGSSANJOAQUIN") != null) {
    var decrypted = CryptoJS.AES.decrypt(localStorage.getItem("userSGSSANJOAQUIN"), process.env.REACT_APP_KEY);
    user = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
} 
} catch (error) {
  //localStorage.clear();
  console.log('user' + error);
}



const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}

