import React, { useEffect, useState, useReducer } from "react";

import axios from "axios";
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_SMS_URL


const GetEnviar = (fono, via) => {
    var cadena1 = fono.slice(1, fono.length);

    
    //return axios.get(API_URL + "send?phonenumber=" + cadena1 + "&channel=sms");
    axios.get(API_URL + "send?phonenumber=" + cadena1 + "&channel=" + via)
    .then(resp => {
        //console.log(resp.data);
        return(resp.data);
    })
    .catch(err => {
        let coderror = err.response.status;
        console.log(coderror);
        return(coderror);
    });
};

const GetVerificar = (fono, code) => {
    var cadena1 = fono.slice(1, fono.length);
    var cadena2 = code;

    return axios.get(API_URL + "verify?phonenumber=" + cadena1 + "&code=" + cadena2)
            .then(response => {
                const { data } = response.data
                return (data)
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: "Error al verificar: (" +fono + ")",
                    showConfirmButton: false,
                    text:"El código ingresado (" + code + ") es incorrecto o ya esta registrado el teléfono.!",
                    timer: 7000
                    });
                return (error)
            })
  };

export default {
    GetEnviar,
    GetVerificar,
};