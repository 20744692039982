import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { motion } from 'framer-motion';


import UbicaUser from '../../components/ubicaUser';

import { getFetch1 } from '../../services/getFetch1.service'

import Swal from 'sweetalert2';

import {
  IconoValidacion1,
  IconoUbica1
} from '../../elementos/Formulario';

import { faCheckCircle, faTimesCircle, faMapMarkedAlt, faStreetView } from '@fortawesome/free-solid-svg-icons';
import Registro_User from "../../components/Registro_User";

const FirstStep = (props) => {

  const { user } = props;

  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: {
      user_localizacion: user.user_localizacion,
      user_accuracy: user.user_accuracy,
      user_pais: user.user_pais,
      user_currentLatitude: user.user_currentLatitude,
      user_currentLongitude: user.user_currentLongitude,
    }
  });

  const [isTracking, setIsTracking] = useState(true);
  const [Latitud, cambiarLatitud] = useState({ campo: '', valido: 'false' });
  const [Longitud, cambiarLongitud] = useState({ campo: '', valido: 'false' });
  const [accuracyMap, cambiaraccuracy] = useState({ campo: '', valido: 'false' });
  const [ubicacion, cambiarUbica] = useState({ campo: '', valido: 'false' });

  const [ubicaciondatapais, cambiarUbicaDataPais] = useState({ campo: '', valido: 'false', Bandera: 'https://raw.githubusercontent.com/MeRahulAhire/intl-tel-code-select/master/phone_icon.png' });
  const [ubicaciondata, cambiarUbicaData] = useState({ campo: 'georeferenciando...', valido: 'false' });


  const onSubmit = (data) => {

    if (Latitud.valido === 'false') {
      Swal.fire({
        icon: 'error',
        title: "¡Error de GPS!",
        showConfirmButton: false,
        text: "Debe dar acceso a la ubicación GPS.",
        timer: 10000
      });

      return <></>;
    }


    data.user_currentLatitude = Latitud.campo;
    data.user_currentLongitude = Longitud.campo;
    data.user_pais = ubicaciondatapais.campo;

    props.updateUser(data);
    props.history.push('/registro/second');
  };

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (isMounted) {

      navigator.geolocation.getCurrentPosition(
        position => {
          let positionLatLon = [position.coords.latitude + ', ' + position.coords.longitude];

          getFetch1(position.coords.latitude, position.coords.longitude)
            .then(data => {
              cambiarUbicaData((prevState) => {
                return { ...prevState, valido: 'true', campo: data }
              });
              cambiarUbicaDataPais((prevState) => {
                return { ...prevState, valido: 'true', campo: data, Bandera: `https://flagpedia.net/data/flags/h80/cl.webp` }
              });
            });

          cambiarUbica((prevState) => {
            return { ...prevState, valido: 'true', campo: positionLatLon }
          });
          cambiarLatitud((prevState) => {
            return { ...prevState, valido: 'true', campo: position.coords.latitude }
          });
          cambiarLongitud((prevState) => {
            return { ...prevState, valido: 'true', campo: position.coords.longitude }
          });
          cambiaraccuracy((prevState) => {
            return { ...prevState, valido: 'true', campo: Math.round(position.coords.accuracy) }
          });

        })

    }
  }, [])

  useEffect(() => {
    return () => {
      setIsMounted(false);
    }
  }, []);

  return (
    <Form className="col-titulo-login" onSubmit={handleSubmit(onSubmit)}>
      <Registro_User />
      <motion.div
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >
        <Container className="container1">
          <Row>
            <Col xs="4" sm="4">
              <Form.Label>Ubicación</Form.Label>
              <img
                src={ubicaciondatapais.Bandera ? ubicaciondatapais.Bandera : process.env.REACT_APP_LOGONOFOTO}
                alt="country-flag"
                id="img"
                className="flag-img1" />

            </Col>
            <Col xs="4" sm="4">
              <IconoUbica1 className="icon-login"
                icon={ubicaciondatapais.valido === 'true' ? faStreetView : faMapMarkedAlt}
                valido={ubicaciondatapais.valido}
              />
            </Col>
            <Col xs="4" sm="4">
              <Form.Group controlid="user_localizacion">
                <Form.Control
                  name="user_localizacion"
                  type="text"
                  placeholder="geolocalizando..."
                  readOnly={true}
                  value={ubicacion.campo}
                  {...register("user_localizacion", {
                    required: false,
                    value: ubicacion.campo,
                    message: 'error de Latitud.' | React.ReactElement,
                  })}
                  {...register("user_accuracy", {
                    required: false,
                    value: accuracyMap.campo,
                    message: 'error de accuracy.' | React.ReactElement,
                  })}
                  className={`${errors.user_localizacion ? 'input-error' : ''}`}
                />
                {errors.user_localizacion && (
                  <p className="errorMsg">{errors.user_localizacion.message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <Form.Group controlid="user_pais">
                <Form.Label>Ubicación Actual (precisión: {accuracyMap.campo} mts.)</Form.Label>
                <Form.Control
                  name="user_pais"
                  as="textarea"
                  placeholder="geolocalizando..."
                  readOnly={true}
                  value={ubicaciondata.campo}
                  {...register("user_pais", {
                    required: false,
                    value: ubicaciondata.campo,
                    message: 'error de Latitud.' | React.ReactElement,
                  })}

                  className={`${errors.user_pais ? 'input-error' : ''}`}
                />
                {errors.user_pais && (
                  <p className="errorMsg">{errors.user_pais.message}</p>
                )}
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group controlid="user_mapa">
                <UbicaUser
                  latitud={Latitud.campo}
                  longitud={Longitud.campo}
                  ubicacion={'[' + ubicacion.campo + ']'}
                  accuracyMap={Math.round(accuracyMap.campo)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="um_data-login-row">
            <div className="d-grid gap-2">
              <Button
                className="btn btn-primary btn-fill"
                variant="primary"
                type="submit"
              >
                Siguiente (Paso 2)
              </Button>
            </div>
          </Row>
        </Container>
      </motion.div>
    </Form>
  );
};

export default FirstStep;;