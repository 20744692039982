import React from 'react';
import { Link, withRouter } from 'react-router-dom';

//import "../../scss/stylesReg.scss";
//import "../scss/stylesReg.scss";

const Registro_User = ({ location: { pathname } }) => {
  const isFirstStep = pathname === '/registro';
  const isSecondStep = pathname === '/registro/second';
  const isThirdStep = pathname === '/registro/third';

  function myFunction() {
    document.body.style.backgroundColor = "#ffff";
    document.body.style.backgroundImage = "url('./assets/icons/FondoBase05.png')";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundPosition ="center center";
  }

  myFunction();


  return (
    <React.Fragment >
        <div id="grid">
            <a href="/">
              <img className="logo-empresaR center" src={process.env.REACT_APP_LOGOEMPRESA} alt="" loading="lazy" style={{cursor: "pointer"}}/>
            </a>
            <a className="col-titulo-registro col-texto-registro">Formulario de Registro</a>
        </div>
        <div className="steps um_data">
          <div className={`${isFirstStep ? 'step active' : 'step'}`}>
            <div>1</div>
            <div>
              {isSecondStep || isThirdStep ? (
                <Link to="/registro">(ok)</Link>
              ) : (
                'Paso'
              )}
            </div>
          </div>
          <div className={`${isSecondStep ? 'step active' : 'step'}`}>
            <div>2</div>
            <div>
              {isThirdStep ? <Link to="/registro/second">(ok)</Link> : 'Paso'}
            </div>
          </div>
          <div className={`${pathname === '/registro/third' ? 'step active' : 'step'}`}>
            <div>3</div>
            <div>Paso</div>
          </div>
        </div>

    </React.Fragment>
  );
};

export default withRouter(Registro_User);
