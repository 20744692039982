import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import sidebarShow from "./sidebarShow";

export default combineReducers({
  auth,
  message,
  sidebarShow,
});
