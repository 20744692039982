import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { faSms, faPhoneVolume, faShare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Redirect } from 'react-router-dom';
import { register_user } from "../../actions/auth";

import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';

import Registro_User from "../../components/Registro_User";
import SmsService from "../../services/sms.service";

import axios from "axios";

const API_URL = process.env.REACT_APP_API_SMS_URL


const ThirdStep = (props) => {

  const { user } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [terminos, cambiarTerminos] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      user_respuesta_sms: user.user_respuesta_sms,
      user_terminos: user.user_terminos,
      user_avatar: '',
    }
  });

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.auth);

  const onSubmit = (data) => {
    setIsLoading(true);
    try {
      if (user.user_fono.length > 9) {


        console.log('lat: ', user.user_currentLatitude)
        console.log('lng: ', user.user_currentLongitude)


        if (!user.user_currentLatitude || !user.user_currentLongitude) {
          Swal.fire({
            icon: 'error',
            title: "¡Error de GPS!",
            showConfirmButton: false,
            text: "Debe dar acceso a la ubicación GPS. Vuelva al paso 1!",
            timer: 10000
          });
          setIsLoading(false);
          return false;
        }

            //se elimino el envio de SMS
            user.user_respuesta_sms = 'S/D';
            user.user_terminos = data.user_terminos;

            props.updateUser(data);
            setIsLoading(true);

            dispatch(
              register_user(
                user.user_nombre.toLowerCase(),
                user.user_apellido.toLowerCase(),
                user.user_fono,
                user.user_email.toLowerCase(),
                user.user_avatar,
                user.user_usuario.toLowerCase(),
                user.user_password,
                user.user_terminos,
                user.user_entidad,
                user.user_respuesta_sms,
                user.user_currentLatitude,
                user.user_currentLongitude,
                user.user_accuracy,
                user.user_pais)
            )
              .then((datos) => {
                
                Swal.fire({
                  icon: 'success',
                  html: "<b>Hola!, acabamos de enviarte un email. (" + user.user_email + "), favor confirma.</b>",
                  showConfirmButton: false,
                  imageUrl: process.env.REACT_APP_LOGOEMPRESA,
                  imageWidth: 220,
                  imageHeight: 45,
                  timer: 15000,
                  footer: '<a href="/"> <b>Debemos verificar tambien tú email </b></a>'
                })
                props.history.push("/");
                window.location.reload();
              })
              .catch(error => {
                console.log('dispatch error: ', error)
                setIsLoading(false);
                Swal.fire({
                  icon: 'error',
                  title: "Error: usuario ya esta registrado!",
                  showConfirmButton: false,
                  text: "El Usuario: (" + user.user_usuario + ") o Teléfono: (" + user.user_fono + "). Ya esta en uso!",
                  timer: 10000
                });
                return (error)
              })
          
      } else {
        setIsLoading(false);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Se detecto un error:',
          showConfirmButton: false,
          text: "No existe un teléfono ingresado, por favor ingreselo en el Paso 1.",
          timer: 10000
        })
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Se detecto un error, vuelva a intentar más tarde',
        text: error.response.data,
        timer: 10000
      });
      console.log('error', error.response.data);

    }
  };

  const onSubmitEnviarSms = (data) => {
    try {
      //SmsService.getEnviar(user.user_fono);
      if (user.user_fono.length > 9) {
        SmsService.GetEnviar(user.user_fono, 'sms');
        setIsLoading1(true);
        Swal.fire({
          icon: 'warning',
          title: "Verificación: (" + user.user_fono + ")",
          showConfirmButton: false,
          html: "<b>acabamos de enviarte un SMS con un código </b><br/><br/>Por favor ingrese el codigo enviado en el formulario. Tenga en cuenta que la entrega de SMS puede tardar un minuto o más.",
          timer: 10000
        })

      } else {
        //update action
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "Se detecto un error: Teléfono (" + user.user_fono + ")",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        })
      }


    } catch (error) {
      if (error.response) {
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "Se detecto un error: Teléfono (" + user.user_fono + ")",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        });
      }
    }
  };

  const onSubmitEnviarCall = (data) => {
    try {
      //SmsService.getEnviar(user.user_fono);
      if (user.user_fono.length > 9) {
        SmsService.GetEnviar(user.user_fono, 'call');
        setIsLoading1(true);
        Swal.fire({
          icon: 'warning',
          title: "Verificación: (" + user.user_fono + ")",
          showConfirmButton: false,
          html: "<b>te vamos a llamar para entregar tú código </b><br/><br/>Por favor ingrese el codigo en el formulario. Tenga en cuenta que la llamada puede tardar un minuto o más.",
          timer: 10000
        })

      } else {
        //update action
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "Se detecto un error: Teléfono (" + user.user_fono + ")",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        })
      }


    } catch (error) {
      if (error.response) {
        setIsLoading1(false);
        Swal.fire({
          icon: 'error',
          title: "Se detecto un error: Teléfono (" + user.user_fono + ")",
          showConfirmButton: false,
          html: "<b>No existe un teléfono ingresado ó no tiene el formato correcto.! </b><br/><br/>Por favor ingreselo nuevamente en el Paso 2.", timer: 10000
        });
      }
    }
  };

  const onChangeTerminos = (e) => {
    cambiarTerminos(e.target.checked);
  }

  if (isLoggedIn) {
    return <Redirect from="/" to="/admin/dashboard" />;
  }

  return (
    <Form className="col-titulo-login" onSubmit={handleSubmit(onSubmit)}>
      <Registro_User />
      <motion.div
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >


        <Form.Group className="um_data" controlid="reg_user">

          <Form.Label><h6>Revise los datos ingresados2:</h6></Form.Label>
          <Row>
            <Col sm={3}>
              <Form.Label>Nombre</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_nombre.toUpperCase()}</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Label>Apellido</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_apellido.toUpperCase()}</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Label>Email</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_email.toUpperCase()}</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Label>Entidad</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_entidad.toUpperCase()}</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Label>Usuario</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_usuario.toUpperCase()}</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Label>Teléfono</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_fono}</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Label>Lat/Lng</Form.Label>
            </Col>
            <Col sm={9} className="rev-text">
              <Form.Label>{user.user_currentLatitude}, {user.user_currentLongitude}</Form.Label>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="um_data" controlid="terminosycondiciones">
          <Row className="center-texto">
            <Col xs="auto" className="my-1">
            <label>
              <input 
                type="checkbox"
                onChange={onChangeTerminos}
                name="user_terminos"
                
                {...register("user_terminos", {
                  required: {
                    value: { terminos },
                    message: 'La aceptación de los Términos, Condiciones y Póliticas de Privacidad es requerido'
                  }
                })}
                className={`${errors.user_terminos ? 'input-error' : ''}`}
              /> Acepto los Terminos, Condiciones y Póliticas de Privacidad
              </label>
              <a className="center-texto" href="https://www.bpsystem.cl/#terminos" target="_blank" data-input='user_terminos'>(leer aquí)</a>
              {errors.user_terminos && (
                <p className="errorMsg">{errors.user_terminos.message}</p>
              )}
            </Col>
          </Row>
        </Form.Group>



        <Button variant="success" type="submit" size="lg" block value="registrar" disabled={isLoading}>
          <span>
            {isLoading ? <span>Verificando... <Spinner animation="grow" variant="warning" /> </span> : 'Registrar nuevo usuario'}
          </span>
        </Button>
      </motion.div>
    </Form>
  );
};

export default ThirdStep;
